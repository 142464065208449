import { createApp } from 'vue';
import ElementPlus from 'element-plus';

import './assets/styles/common.scss';
import 'element-plus/lib/theme-chalk/index.css';

import router from './router.config';
import store from './store';

import App from './App.vue';
import {
  $get,
  $post
} from './api';
const vm = createApp(App);
vm.config.globalProperties.$get = $get;
vm.config.globalProperties.$post = $post;
vm.use(ElementPlus);
vm.use(router);
vm.use(store)
vm.mount('#app');