/*jshint esversion: 6*/
import { createStore } from 'vuex';

const store = createStore({
    state: {
        currentTab: 'home',
        willSelectedTab: null,
        tabs: [{
            title: "首页",
            name: "home"
        }],

    },
    mutations: {
        addTab(state) {
            state.willSelectedTab &&
                !state.tabs.some(item => item.name === state.willSelectedTab.name) &&
                state.tabs.unshift(state.willSelectedTab);

        },
        willAddTab(state, tab) {
            state.willSelectedTab = tab;
        },
        setCurrentTab(state, tab) {
            tab && (state.currentTab = tab);
        }
    }
})

export default store;