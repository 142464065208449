/*jshint esversion:6*/
import axios from 'axios';
// import { ElMessage as message } from 'element-plus';
axios.interceptors.request.use(function (config) {
    console.log(config);
    let url = config.url;
    if (url.indexOf('https://') !== 0 && url.indexOf('http://') !== 0 && url.indexOf('//') !== 0) {
        url = `${url}`;
    }
    config.url = url;
    console.log(config);
    return config;
}, function (error) {
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
	// console.log(response,2020)
    // if (response.status === 200) {
        switch (response.data.status) {
            //响应成功 直接返回
            case 1:
                return Promise.resolve(response.data.data);
            case 0:
                // message({
                //     message: response.data.message || '未知原因的错误请求',
                //     duration: 0,
                //     showClose: true
                // })
                return Promise.reject(response.data);
            case 401:
                return Promise.reject(response.data);
            default:
                break;
        }
    // }
}, function (error) {
    switch (error.response.status) {
        case 404:
            message.error({
                message: '请求失败，请求所希望得到的资源未被在服务器上发现',
                duration: 0,
                showClose: true
            });
            break;
        case 500:
            message.error({
                message: '服务器遇到了一个未曾预料的状况，导致了它无法完成对请求的处理',
                duration: 0,
                showClose: true
            });
            break;
        default:
            break;
    }
    return Promise.reject(error.response.data);
});

export function $get(url, params) {
    return axios.get(url, {
        params
    });
}
export function $post(url, data) {
    return axios.post(url, data);
}
export function $put(url, _data) {
    return axios.put(url, _data);
}
export function $delete(url, params, data) {
    return axios.delete(url, {
        data,
        params
    });
}