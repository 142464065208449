<template>
  <div id="app">
    <router-view v-slot="{ Component }">
      <transition>
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script>
export default {
  name: "app",
  data() {
    return {};
  },
  created() {
    // console.log("===============================", this.$router);
    // this.$post("/account/login", {
    //   UserName: "888",
    //   Password: "888",
    // });
  },
  computed: {
    currentTab() {
      return this.$store.state.currentTab || "";
    },
    editableTabs() {
      return this.$store.state.tabs || [];
    },
  },
  methods: {
    removeTab() {},
    goToUrl(x, item) {
      console.log(x, this.editableTabs);
      this.$router.push("/sysConfig/lableManagement");
    },
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
html,body,#app{
	height: 100%;
}
</style>
