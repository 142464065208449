import { createRouter, createWebHashHistory } from 'vue-router';
import store from './store';
const routers = createRouter({
    history: createWebHashHistory(),
    routes: [{
        // 首页
        path: '/home',
        name: 'home',
        component: () =>
            import( /* webpackChunkName: "group-home" */ './pages/dashboard/Desktop.vue')
    }, {
        //健康档案
        path: '/customerManagement/healthrecords',
        name: 'healthrecords',
        component: () =>
            import('./pages/sysConfig/lableManagement')
    }, {
        //标签管理
        path: '/sysConfig/lableManagement',
        name: 'lableManagement',
        component: () =>
            import('./pages/sysConfig/lableManagement')
    }, {
        //登录页
        path: '/',
        name: 'login',
        component: () =>
            import('./pages/login/AppLogin')
    }]
});
routers.afterEach((to, from) => {
    store.commit('addTab');
    store.commit('setCurrentTab', to.name);
})

export default routers;